
// A sweet helper function to create pagination object
const createPaginationObjects = (length, page, slug, increment = 2) =>
	Array.from({ length }, (_, i) => ({
		link: `/${slug}/${i + increment}/`,
		index: i + increment,
		current: page === i + increment,
    }));
    
export default (currentPage, numPages, root, slug) => {
    // Create the navigation link
	let navItems = [
		{
			link: root,
			index: 1,
			current: currentPage === 1,
		},
	];
	if (numPages <= 5) {
		navItems = [
			...navItems,
			...Array.from({ length: numPages - 1 }, (_, i) => ({
				link: `/${slug}/${i + 2}/`,
				index: i + 2,
				current: currentPage === i + 2,
			})),
		];
	} else {
		// We have a situation where we have to show the first
		// item, three items around the current one
		// and also the last item
		/* eslint-disable no-lonely-if */
		if (currentPage <= 3) {
			// If the current one is closer to the start
			navItems = [
				...navItems,
				...createPaginationObjects(3, currentPage, slug),
				{
					separator: true,
					index: 'starter-separator',
				},
				{
					link: `/${slug}/${numPages}/`,
					index: numPages,
					current: false,
				},
			];
		} else if (currentPage > numPages - 3) {
			// If the current one is closer to the last one
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'finisher-separator',
				},
				...createPaginationObjects(4, currentPage, slug, numPages - 3),
			];
		} else {
			navItems = [
				...navItems,
				{
					separator: true,
					index: 'starter-separator',
				},
				...createPaginationObjects(3, currentPage, slug, currentPage - 1),
				{
					separator: true,
					index: 'finisher-separator',
				},
				{
					link: `/${slug}/${numPages}/`,
					index: numPages,
					current: false,
				},
			];
		}
    }

    return navItems;
}