import React from 'react'
import { RichText, Date } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import Layout from '../components/layouts'
import generateNavItems from '../utils/generateNavItems'
import moment from 'moment'
import 'moment/locale/ro';
import hiking from '../images/hiking.svg'
import hikingMountain from '../images/hiking_mountain.svg'

// Function to retrieve a small preview of the post's text
const firstParagraph = (post => {

    // Find the first text slice of post's body
    let firstTextSlice = post.body.find(slice => slice.type === 'text');
    if (firstTextSlice != null) {
        // Set the character limit for the text we'll show in the homepage
        const textLimit = 300
        let text = RichText.asText(firstTextSlice.primary.text)
        let limitedText = text.substring(0, textLimit)

        if (text.length > textLimit) {
            // Cut only up to the last word and attach '...' for readability
            return (
                <p>{limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'}</p>
            );
        } else {
            // If it's shorter than the limit, just show it normally
            return <p>{text}</p>;
        }
    } else {
        // If there are no slices of type 'text', return nothing
        return null;
    }
})

// A summary of the Blog Post
const PostSummary = ({ post }) => {

    // Store and format the blog post's publication date
    let postDate = Date(post.date);
    postDate = postDate ?
        new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        }).format(postDate) :
        '';

    // Default title when post has no title set
    const defaultTitle = "Untitled"
    const categoryTitle = post.category && post.category.categories_page_title &&
        post.category.categories_page_title[0] && post.category.categories_page_title[0].text;
    const categoryUrl = post.category && post.category._meta && post.category._meta.uid;

    moment.locale("ro");
    moment.createFromInputFallback = function (config) {
        // unreliable string magic, or
        config._d = new Date(config._i);
    };

    return (
        <div className="post-summary" key={post.id} >
            <a href={linkResolver(post._meta)}>
                {post.hero_imageSharp && <Img
                    fluid={post.hero_imageSharp.childImageSharp.fluid}
                    alt={RichText.asText(post.title)}
                />}

            </a>
            <div className="blog-meta">
                <div className="blog-post-date">
                    {moment(postDate).format('DD')}
                    <span>{moment(postDate).format('MMMM')}</span>
                </div>
                <div className="blog-post-meta">
                    <a href={linkResolver(post._meta)}>
                        <h2>
                            {/* We render a link to a particular post using the linkResolver for the url and its title */}
                            {RichText.asText(post.title).length !== 0 ? RichText.asText(post.title) : defaultTitle}
                        </h2>
                    </a>
                    {categoryTitle && <div className="blog-post-category">
                        <a href={`/${categoryUrl}`}>{categoryTitle}</a>
                    </div>}
                    {/* Renders a small preview of the post's text */}
                    {firstParagraph(post)}
                    {getTags(post._meta.tags)}
                </div>
            </div>
        </div>
    );
}

const getTags = tags => {
    if (tags.length) {
        return (
            <div className="blog-post-tags">
                {tags.map((item, i) => {
                    const itemSlug = item.split(' ').join('-');
                    return (
                        <a key={i} href={`/tag/${itemSlug}`}>#{item}</a>
                    )
                })}


            </div>
        )
    }
    return false
}

export default ({ pageContext }) => {
    const { currentPage, posts, numPages, slug, categoryName, maintenance, logo } = pageContext;
    if (!posts) return (
        <Layout>
            <div>Nothing</div>
        </Layout>
    );

    if (maintenance) return (
        <div className="maintenance">
            <Img
                fluid={logo.childImageSharp.fluid}
                alt="Ce sa vizitam"
            />
        </div>
    )

    const root = slug === 'page' ? '/' : `/${slug}`;
    const navItems = generateNavItems(currentPage, numPages, root, slug);

    const prev = currentPage !== 1 ? currentPage - 1 > 1 ? `/${slug}/${currentPage - 1}` : root : false;
    const next = currentPage + 1 <= numPages ? `/${slug}/${currentPage + 1}` : false;

    return (
        <Layout>
            <div>
                {categoryName && <div className="category-name">
                    <h2>{categoryName}</h2>
                </div>}
                <div className="blog-posts container">
                    {posts.map((post) => {
                        return <PostSummary post={post.node} key={post.node._meta.id} />
                    })}
                </div>
                <div className="section-pagination">
                    <nav className="pagination">
                        {prev && (
                            <Link to={prev} className="pagination-previous">
                                Pagina anterioară
					</Link>
                        )}
                        {next && (
                            <Link to={next} className="pagination-next">
                                Pagina următoare
					</Link>
                        )}
                        <ul className="pagination-list">
                            {navItems.map(item => (
                                <li key={item.index}>
                                    {item.separator ? (
                                        <span className="pagination-ellipsis">
                                            &hellip;
								</span>
                                    ) : (
                                            <Link to={item.link} className={`pagination-link ${item.current ? 'is-current' : ''}`} aria-label={`Goto page ${item.index}`} >
                                                {item.index}
                                            </Link>
                                        )}
                                </li>
                            ))}
                            {currentPage < navItems.length ? <img src={hiking} alt={"drumet"}/> : <img src={hikingMountain} alt={"drumet"}/>}
                        </ul>
                    </nav>
                </div>
            </div>
        </Layout>
    )
}
